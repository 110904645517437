import React,{useState} from 'react'

import Banner from './Banner'
import Carousel from './Carousel'
import Why from './Why'
import Vision from './Vision'
import Center from './Center'
import Ecosystem from './Ecosystem'
import RoadMap from './RoadMap'
import Tokenomics from './Tokenomics'
import SDK from './SDK'
import FAQ from './FAQ'
import News from './News'

export default ()=> {
  return (
	<div className="main">
		<Banner />
		<Why />
		<Vision />
		<Center />
		<Ecosystem />
		<RoadMap />
		<Tokenomics />
		<FAQ />
		<News />
	</div>
  );
}

