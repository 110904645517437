import React,{useContext,useEffect} from 'react'
import { themeContext } from '../../../store/configProvider.tsx'
import AOS from 'aos';
import 'aos/dist/aos.css';

import roadMap from '../../../assets/roadMap.png';
import roadMap_light from '../../../assets/roadMap_light.png';
import line from '../../../assets/line.png';

export default ()=> {
	const { theme } = React.useContext(themeContext)
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-6 pt-16 sm:pt-32">
    	<div className="max-w-7xl mx-auto px-0 sm:px-4 sm:px-6 lg:px-6">
    		<div className="text-5xl sm:text-6xl dark:text-white text-gray-800 mb-5">
    			ROADMAP
    		</div>
    	</div>
    	<div data-aos="fade-up" className="relative rounded-5xl h-[48rem] md:h-[32rem] bg-cover bg-center" style={{ backgroundImage: `url(${theme=='dark'?roadMap:roadMap_light})` }}>
			<div className="absolute z-10 w-full h-full grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 px-4 py-2">
				<div className="pt-0 md:pt-32 lg:pt-48 xl:pt-56">
					<div className="flex flex-col col-span-1 md:flex-col-reverse" data-aos="fade-right">
						<div className="my-2 text-xl">
							MAR  2022
						</div>
						<div className="text-base">
							Pre-IDO ActivitiesTeam DisclosuresPlatform Development Marketplace Testing Exnetwork Capital onboarding
						</div>
					</div>
				</div>
				<div className="pt-0 md:pt-72 lg:pt-80">
					<div className="flex flex-col col-span-1" data-aos="fade-up" data-aos-delay="100">
						<div className="my-2 text-xl">
							Q2  2022
						</div>
						<div className="text-base">
							Crowdsale Completion
							Launch on BNB Chain
							Gamified NFT PacksCommunity Activity LaunchMarketing Partnerships
						</div>
					</div>
				</div>
				<div className="pt-0 md:pt-12 lg:pt-18">
					<div className="flex flex-col col-span-1 md:flex-col-reverse" data-aos="fade-down" data-aos-delay="300">
						<div className="my-2 text-xl">
							Q3  2022
						</div>
						<div className="text-base">
							Mobile App LaunchLaunch on ANFS NetworkSecond Community ActivityListing on Centralized Exchange
						</div>
					</div>
				</div>
				<div className="pt-0 md:pt-56 lg:pt-56">
					<div className="flex flex-col col-span-1" data-aos="fade-left"  data-aos-delay="300">
						<div className="my-2 text-xl">
							Q4  2022
						</div>
						<div className="text-base">
							Strategic Partner Activity Launch on Additional
							NetworksAdding additional networksSpecial FeaturesFAMDAO 2.0 Release
						</div>
					</div>
				</div>
			</div>
			<div className="animate-line absolute w-full h-full bg-no-repeat bg-contain bg-center overflow-hidden" style={{ backgroundImage: `url(${line})` }}></div>
    	</div>
    </div>
  );
}

