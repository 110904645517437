import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import vision from '../../../assets/vision.png';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="bg-gradient-to-b from-pgray-50 to-pwhite dark:from-pgray-900 dark:to-pgray-900 flex">
    	<div className="w-full pt-16">
    		<div className="max-w-5xl mx-auto text-center px-4 sm:px-6 lg:px-6">
    			<div className="flex justify-center items-center">
    				<div className="bg-green-400 rounded-full w-2 h-2 mr-4"></div>
    				<div className="bg-green-400 rounded-full w-2 h-2 mr-4"></div>
    				<div className="bg-green-400 rounded-full w-2 h-2 mr-4"></div>
    				<div className="text-3xl mr-4 dark:text-white text-gray-900">VISION</div>                
    				<div className="bg-green-400 rounded-full w-2 h-2 mr-4"></div>
    				<div className="bg-green-400 rounded-full w-2 h-2 mr-4"></div>
    				<div className="bg-green-400 rounded-full w-2 h-2 mr-4"></div>
    			</div>
    			<div className="mt-8 text-2xl sm:text-3xl sm:leading-relaxed dark:text-white text-gray-900 ">
    				To provide a secure, transparent and affordable access to create and trade innovative financial products for everyone in crypto, thus to actively promote an equal financial inclusion around the world.
    			</div>
    			<img  data-aos="zoom-in" className="p-4 w-24 sm:w-48 mx-auto mb-2 rounded-full mt-8" src={vision}/>
    		</div>
    	</div>
    </div>
  );
}

