import React,{useState,useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import new1 from '../../../assets/20monthPlan.png';
import new2 from '../../../assets/new2.png';
import new3 from '../../../assets/new3.png';
import new4 from '../../../assets/new4.png';
import new5 from '../../../assets/FamDAOLove.png';
import new6 from '../../../assets/new6.jpg';
import new7 from '../../../assets/LeadingPartners.jpg';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
	let news = [{
		href:'https://medium.com/@famdao/the-famdao-team-is-launching-a-20-month-continuous-upgrade-plan-3bd1a3356230',
		pic:new1,
		title:'The FAMDAO Team is launching a 20-month Continuous Upgrade Plan',
		date:'Jul 15, 2022'
	},
	// {
	// 	href:'https://medium.com/@famdao/famdao-reflections-official-1st-month-of-operations-3f277b08c1b2',
	// 	pic:new2,
	// 	title:'FAMDAO Reflections: Official 1st Month of Operations!',
	// 	date:'Jun 27, 2022'
	// },{
	// 	href:'https://medium.com/@famdao/famdao-2022-roadmap-updates-governance-ecology-and-data-3c2951af8a68',
	// 	pic:new3,
	// 	title:'FAMDAO: 2022 Roadmap Updates, Governance, Ecology and Data',
	// 	date:'Jun 22, 2022'
	// },{
	// 	href:'https://cryptomode.com/famdao-the-dao-guided-by-a-family-treasury/',
	// 	pic:new4,
	// 	title:'FamDAO – The DAO guided by a FAMILY Treasury',
	// 	date:'March 17, 2022'
	// },
	{
		href:'https://cryptomode.com/famdao-a-dao-based-on-family-and-love/',
		pic:new5,
		title:'FamDAO – A DAO based on Family and Love',
		date:'April 4, 2022'
	},
	// {
	// 	href:'https://cryptomode.com/famdao-salvation-in-the-post-pandemic-era/',
	// 	pic:new6,
	// 	title:'FAMDAO, Salvation in the Post-Pandemic Era',
	// 	date:'April 4, 2022'
	// },
	{
		href:'https://thecryptobasic.com/2022/03/20/famdao-the-multi-chain-family-solution-announces-funding-plans-by-leading-partners/',
		pic:new7,
		title:'FamDAO, the Multi-Chain Family Solution, Announces Funding Plans by Leading Partners',
		date:'March 20, 2022'
	}]
	
	
  return (
    <div className="dark:bg-dark bg-white max-w-8xl mx-auto px-4 sm:px-6 lg:px-6 pt-16 sm:pt-32">
    	<div className="max-w-7xl mx-auto px-0 sm:px-4 sm:px-6 lg:px-6">
    		<div className="text-5xl sm:text-6xl dark:text-white text-gray-800 mb-5">
    			News and Events
    		</div>
    		<div className="text-xl sm:text-2xl dark:text-white text-gray-800 mb-8 sm:mb-12">
    			
    		</div>
    	</div>
    	<div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-x-8 gap-y-8 ">
    		{
    			news.map((item,index)=>{
    				return (
    					<a data-aos="flip-right" key={index} href={item.href} target="_blank" className=" transition ease-out transform hover:-translate-y-4 group dark:bg-gray-800 bg-gray-50 dark:hover:bg-gray-700 hover:bg-gray-100 rounded-5xl">
    						<div className="h-64 bg-cover bg-center overflow-hidden rounded-3xl" style={{ backgroundImage: `url(${item.pic})` }}>
    							<div className="p-4 bg-gradient-to-b from-[NEWS-form] to-transparent">
    								<div className="font-bold text-white">
    									{item.title}
    								</div>
    								<div className="mt-1 text-white group-hover:opacity-100 opacity-90">
    									{item.date}
    								</div>
    							</div>
    						</div>
    					</a>
    				)
    			})
    		}
    	</div>
    </div>
  );
}

