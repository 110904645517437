import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import center1 from '../../../assets/Research.png';
import center2 from '../../../assets/Snapshot.png';
import center3 from '../../../assets/Emergency.png';
import center4 from '../../../assets/Committees.png';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="dark:bg-dark bg-white flex">
    	<div className="w-full pt-16 pb-8 sm:pt-32 sm:pb-8">
    		<div className="max-w-8xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-8 sm:gap-x-8 sm:gap-y-8 px-4 sm:px-6 lg:px-6">
    			<div className="md:col-span-3 transition ease-out transform hover:-translate-y-4 ">
    				<div className="rounded-5xl h-[32rem] sm:[h-[24rem]] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${center1})` }}>
    					<div className="p-10 sm:p-18 dark:text-white text-gray-800">
    						<div data-aos="fade-right" className="font-medium text-2xl sm:text-4xl text-canter1 mb-5 leading-tight">
    							Research forum
    						</div>
    						<div data-aos="fade-right" className="font-medium text-lg sm:text-xl mb-5 leading-tight">
    							All ideas and proposals are initially published on the Research forum.
    
    						</div>
    					</div>
    				</div>
    			</div>
    			<div className="md:col-span-3 transition ease-out transform hover:-translate-y-4 ">
    				<div className="rounded-5xl h-[32rem] sm:[h-[24rem]] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${center2})` }}>
    					<div className="p-10 sm:p-18 dark:text-white text-gray-800">
    						<div data-aos="fade-left" className="font-medium text-2xl sm:text-4xl text-canter2 mb-5 leading-tight">
    							Snapshot
    						</div>
    						<div data-aos="fade-left" className="font-medium text-lg sm:text-xl mb-5 leading-tight">
    							The more FAM users have, the greater the decision-making power the voter gets(a 100 FAM token holder would have 10 votes and a 10,000 FAM token holder would have 100 votes. This levels out the playing field and makes sure that every FAM token holders vote matters.)
    						</div>
    					</div>
    				</div>
    			</div>
    			<div className="md:col-span-2 transition ease-out transform hover:-translate-y-4 ">
    				<div className="flex sm:items-start sm:items-end rounded-5xl h-[32rem] sm:[h-[24rem]] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${center3})` }}>
    					<div className="p-10 sm:p-18 dark:text-white text-gray-800">
    						<div data-aos="fade-right" className="font-medium text-2xl sm:text-4xl text-canter3 mb-5 leading-tight">
    							Emergency track
    						</div>
    						<div data-aos="fade-right" className="font-medium text-lg sm:text-xl mb-5 leading-tight">
    							In an emergency, the voting may be set to a very short time, and users need to respond quickly to face the emergency.
    						</div>
    					</div>
    				</div>
    			</div>
    			<div className="md:col-span-4 transition ease-out transform hover:-translate-y-4 ">
    				<div className="rounded-5xl h-[32rem] sm:[h-[24rem]] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${center4})` }}>
    					<div className="p-10 sm:p-18 dark:text-white text-gray-800">
    						<div data-aos="fade-left" className="font-medium text-2xl sm:text-4xl text-canter4 mb-5 leading-tight">
    							Committees
    						</div>
    						<div data-aos="fade-left" className="font-medium text-lg sm:text-xl mb-5 leading-tight">
    							In addition to all this, to streamline routine governance operations, some of the activities of the DAO are governed by committees.
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
  );
}

