import logo from '../../assets/logo.png';
import logo_light from '../../assets/logo_light.png';
import discord from '../../assets/discord.png';
import twitter from '../../assets/twitter.png';
import telegram from '../../assets/telegram.png';

import sun from '../../assets/sun.png';
import moon from '../../assets/moon.png';

import React,{useContext} from 'react'
import { themeContext } from '../../store/configProvider.tsx'

export default ()=> {
	const { theme,changeTheme } = React.useContext(themeContext)
	
	const change = () => {
		if(!localStorage.theme || localStorage.theme == 'dark'){
			localStorage.theme = 'light';
			changeTheme('light');
			document.documentElement.classList.remove('dark')
		}else{
			localStorage.theme = 'dark';
			changeTheme('dark');
			document.documentElement.classList.add('dark')
		}
	}
  return (
	<div className="footer">
		<div className="relative w-full dark:bg-dark bg-white mt-16">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 mb-16">
				<div className="grid grid-cols-2 sm:grid-cols-5 gap-8">
					<div className="flex flex-col space-y-2">
						<div className="text-gray-500 font-medium">
							FAM FINANCE
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="https://finance.famdao.org/#/swap">FAMSWAP</a>
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="https://info.famdao.org/">Price</a>
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="https://finance.famdao.org/#/mining">Farms</a>
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="https://finance.famdao.org/#/asset">My Assets</a>
						</div>
					</div>
					<div className="flex flex-col space-y-2">
						<div className="text-gray-500 font-medium">
							FAMILY
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="https://discord.gg/FqEsjM8EmU">Discord</a>
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="https://t.me/famdao">Telegram</a>
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="https://twitter.com/FAM_DAO">Twitter</a>
						</div>
					</div>
					<div className="flex flex-col space-y-2">
						<div className="text-gray-500 font-medium">
							LEARN
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" href="FAMDAO_Whitepaper_Rev-1.pdf">WHITEPAPER</a>
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a href="#FAQ">FAQ</a>
						</div>
						<div className="text-gray-800 dark:text-white cursor-pointer">
							<a target="_blank" hfef="https://medium.com/@famdao">Medium</a>
						</div>
					</div>
					<div className="hidden sm:col-span-2 flex flex-col space-y-2">
						<div className="">
							<div className="">
								<div className="text-gray-500 font-medium mb-2">
									NEWSLETTER
								</div>
								<div className="flex flex-col sm:flex-row w-full space-y-3 sm:space-x-3 sm:space-y-0">
									<div className="w-full relative rounded-md shadow-sm">
										<input placeholder="john.smith@company.com" required className="px-3 py-2 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-base rounded-md placeholder-pgray-500 text-gray-700 dark:text-white bg-gray-100 dark:bg-gray-800"/>
									</div>
									<button className="cursor-pointer whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border-transparent rounded-md text-base text-white font-medium bg-blue-500 hover:bg-blue-700 hover:text-white hover:no-underline">
										Subscribe
									</button>
								</div>
							</div>
						</div>
						<div className="text-base text-gray-400">
							What took days now takes seconds. What took teams now takes PARSIQ.
						</div>
					</div>
				</div>
			</div>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 border-t dark:border-gray-800 border-gray-100">
				<div className="sm:flex justify-between items-center py-6 sm:space-x-10">
					<div className="flex flex-col sm:flex-row justify-start sm:space-x-3 space-y-3 sm:space-y-0 grow">
						<a>
							<img className="my-auto h-7 w-auto sm:h-7" src={theme=='dark'?logo:logo_light}/>
						</a>
					</div>
					<div className="mt-3 sm:mt-0 sm:mx-3 flex-row flex justify-self-end">
						<a target="_blank" href="https://discord.gg/FqEsjM8EmU">
							<img className="h-6 w-6 mx-1.5" src={discord}/>
						</a>
						<a target="_blank" href="https://twitter.com/FAM_DAO">
							<img className="h-6 w-6 mx-1.5" src={twitter}/>
						</a>
						<a target="_blank" href="https://t.me/famdao">
							<img className="h-6 w-6 mx-1.5" src={telegram}/>
						</a>
						<div className="hidden cursor-pointer text-gray-800 dark:text-white" onClick={()=>{change()}}>
							<img className="h-6 w-6 mx-1.5" src={theme=='dark'?sun:moon}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  );
}

