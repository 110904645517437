import React,{useEffect,useState} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import bannerBg from '../../../assets/banner.png';

export default ()=> {
	let titleLetter = "What is FAMDAO?";
	let subTitleLetter = "FAMDAO is an innovative decentralized reserve currency protocol based on the FAM Token.";
	let titleMultiple = 200;
	let subTitleMultiple = 100;
	let delay = 500;
	const [title,settitle] = React.useState('');
	const [subTitle,setsubTitle] = React.useState('');
	
	useEffect(() => {
		 AOS.init();
		 
		 let emptyTitle = '';
		 titleLetter.split("").forEach((item,index)=>{
			 setTimeout(()=>{
				 emptyTitle +=item;
				 settitle(emptyTitle);
			 },index*titleMultiple + delay)
		 })
		 
		 let emptySubTitle = '';
		 subTitleLetter.split("").forEach((item,index)=>{
			 setTimeout(()=>{
				 emptySubTitle +=item;
				 setsubTitle(emptySubTitle);
			 },index*subTitleMultiple + (titleLetter.length+4) * titleMultiple + delay)
		 })
		 
	}, [])
  return (
    <div className="relative flex min-h-screen dark:bg-dark bg-white">
    	<div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0">
    		<div className="z-20 relative pb-8 max-w-full lg:max-w-3xl lg:w-full">
    			<main className="mt-10 mx-auto max-w-full lg:max-w-8xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
    				<div className="sm:text-center lg:text-left">
    					<h1 className="relative tracking-tight font-extrabold font-display dark:text-white text-gray-900 text-6xl sm:text-6xl md:text-8xl lg:text-9xl">
    						<span className="block xl:inline">{title}</span>
    					</h1>
    					<p className="mt-3 leading-8 text-2xl sm:text-4xl sm:leading-12 dark:text-white text-white sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 ">
    						{subTitle}
    					</p>
    					<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
    						<div className="mt-3 sm:mt-0 sm:ml-6">
    							<a target="_blank" href="https://finance.famdao.org/#/home" className="bg-gradient-to-r from-[STAKE-form] to-[STAKE-to] hover:from-[STAKE-form-hover] hover:to-[STAKE-to-hover] cursor-pointer w-full flex items-center justify-center px-6 py-2 text-base font-medium rounded-full text-white md:py-2 md:text-lg md:px-6 ">
    								STAKE NOW!
    							</a>
    						</div>
    					</div>
    				</div>
    			</main>
    		</div>
    	</div>
    	
    	<div className="z-10 absolute bottom-0 md:inset-y-0 left-0 w-full lg:w-3/5 backdrop-filter backdrop-blur-md dark:bg-gray-800 bg-gray-700 dark:bg-opacity-50 bg-opacity-30 h-4/6 md:h-full"></div>
    	
    	<div className="z-8 absolute lg:inset-y-0 lg:right-0 h-full w-full">
    		<div className="w-full bg-auto bg-fixed h-full lg:w-full lg:h-full" style={{ backgroundImage: `url(${bannerBg})` }}></div>
    	</div>
    </div>
  );
}

