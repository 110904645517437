import React,{useState,useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import top from '../../../assets/top.png';
import down from '../../../assets/down.png';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
	const [FAQ, setFAQ] = React.useState([
		{
			item:'How do I join FAMDAO community?',
			isOpen:false,
			info:'You can click the "FAMILY" button in the top menu, choose either Discord or Telegram according to your preference to join.Please enjoy the FAM Community!'
		},
		{
			item:'Where can I get FAM Token?',
			isOpen:false,
			info:'Click top menu "FAM FINANCE" , click swap , link wallet , and swap your Usdt to FAM Token.'
		},
		{
			item:'What wallets does FamSwap support?',
			isOpen:false,
			info:"All common mainstream wallets in the market can be used , such as Metamask , Bitkeep , Token pocket etc. And please remember the correct URL: ",
			url:"https://finance.famdao.org"
		},
		{
			item:'How to earn more FAM Token?',
			isOpen:false,
			info:' Enter the FAMSWAP , stake your FAM$ for up to 100-140% APY!'
		},
	]); 
	
	const clickFAQ = (index)=>{
		let arr = [...FAQ];
		arr[index].isOpen = !arr[index].isOpen;
		setFAQ(arr);
	}
  return (
    <div className="dark:bg-dark bg-white flex">
		<a name="FAQ"></a>
    	<div className="w-full">
    		<div className="max-w-7xl pt-16 pb-8 sm:pt-32 mx-auto grid grid-cols-1 md:grid-cols-3 gap-x-8 px-4 sm:px-6 lg:px-6">
    			<div className="col-span-1">
    				<div className="text-5xl sm:text-6xl dark:text-white text-gray-800 mb-5">
    					FAQ
    				</div>
    				<div className="text-xl sm:text-2xl dark:text-white text-gray-800 mb-10 sm:mb-12">
    					
    				</div>
    			</div>
    			<div className="col-span-2 flex flex-col space-y-4">
    				{
    					FAQ.map((item,index)=>{
    						return (
    							<div data-aos="fade-left" data-aos-delay={100+index*100} key={index} className="flex flex-col">
    								<div className="cursor-pointer flex items-center justify-between dark:bg-gray-800 bg-gray-50 p-5 text-left rounded-2xl" onClick={()=>{
										clickFAQ(index);
									}}>
    									<div className="font-medium dark:text-white text-gray-800">
    										{item.item} {item.isOpen}
    									</div>
										<img className="dark:text-white text-gray-800 h-3 w-3 opacity-60 flex-none ml-6" src={item.isOpen ? top:down}/>
    								</div>
									{
										item.isOpen ? (
											<div className="overflow-hidden accordion">
												<div className="whitespace-pre-wrap dark:text-white text-gray-800 px-5 py-5">
													{item.info}
													{
														item.url?(
															<a href={item.url} target="_blank" className="text-token1 underline">{item.url}</a>
														):('')
													}
												</div>
											</div>	
										):('')
									}
    							</div>
    						)
    					})
    				}
    			</div>
    		</div>
    	</div>
    </div>
  );
}

