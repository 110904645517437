
import why1 from '../../../assets/FNOSG.png';
import why2 from '../../../assets/FEGO.png';
import why3 from '../../../assets/reWARDS.png';
import why4 from '../../../assets/RCCbg.png';

import rcc from '../../../assets/rcc.png';

import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
	
  return (
    <div className="dark:bg-dark bg-white flex">
    	<div className="w-full pt-16 pb-8 sm:pt-32 sm:pb-32">
    		<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 mb-16">
    			<div className="text-4xl sm:text-8xl dark:text-white text-gray-800 mb-5 leading-tight mb-10">
    				Why is FAMDAO?
    			</div>
    			<div className="text-xl sm:text-4xl dark:text-white text-gray-800 mb-5 leading-tight">
    				FAMDAO Protocol is a decentralized asset management protocol in Web 3, which allows everyone to create and manage customized pools-based portfolios with a variety of on-chain assets (tokens, NFTs, derivatives and etc.) via smart contract.
    			</div>
    		</div>
    		
    		<div className="max-w-8xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-x-8 sm:gap-y-8 px-4 sm:px-6 lg:px-6">
    			<div className="col-span-1" data-aos="zoom-in">
    				<div className="rounded-5xl h-[32rem] sm:h-[48rem] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${why1})` }}>
    					<div className="p-8 sm:p-10 dark:text-white text-gray-800">
    						<div className="font-medium text-2xl sm:text-2xl mb-5 leading-tight">FNOSG</div>
    						<div className="font-medium text-xl sm:text-xl mb-5 leading-tight">
    							FNOSG evolves the node operator registry so that it is as decentralized, permissionless, and secure as possible.
    						</div>
    						<a className="font-regular text-lg sm:text-lg mb-5 leading-tight group flex cursor-pointer">
    							<span className="group-hover:underline mr-1">
    								
    							</span>
    						</a>	
    					</div>
    				</div>
    			</div>
    			<div className="col-span-1" data-aos="zoom-in" data-aos-delay="100">
    				<div className="rounded-5xl h-[32rem] sm:h-[48rem] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${why2})` }}>
    					<div className="p-8 sm:p-10 dark:text-white text-gray-800">
    						<div className="font-medium text-2xl sm:text-2xl mb-5 leading-tight">FEGO</div>
    						<div className="font-medium text-xl sm:text-xl mb-5 leading-tight">
    							Mission of FEGO is to grow the greater liquid staking ecosystem through fast and unimpeded grants to developers.
    						</div>
    						<a className="font-regular text-lg sm:text-lg mb-5 leading-tight group flex cursor-pointer">
    							<span className="group-hover:underline mr-1">
    								
    							</span>
    						</a>	
    					</div>
    				</div>
    			</div>
    			<div className="col-span-1" data-aos="zoom-in"  data-aos-delay="200">
    				<div className="rounded-5xl h-[32rem] sm:h-[48rem] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${why3})` }}>
    					<div className="p-8 sm:p-10 dark:text-white text-gray-800">
    						<div className="font-medium text-2xl sm:text-2xl mb-5 leading-tight">reWARDS Committee</div>
    						<div className="font-medium text-xl sm:text-xl mb-5 leading-tight">
    							reWARDS Committee tasked with incentive management for FAM liquid staking assets.
    						</div>
    						<a className="font-regular text-lg sm:text-lg mb-5 leading-tight group flex cursor-pointer">
    							<span className="group-hover:underline mr-1">
    								
    							</span>
    						</a>	
    					</div>
    				</div>
    			</div>
    			<div className="col-span-1" data-aos="zoom-in">
    				<div className="rounded-5xl h-[32rem] sm:h-[21rem] bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${why4})` }}>
    					<div className="p-8 sm:p-10 dark:text-white text-gray-800">
    						<div className="font-medium text-2xl sm:text-2xl mb-5 leading-tight">RCC</div>
    						<div className="font-medium text-xl sm:text-xl mb-5 leading-tight">
    							The Committee is dedicated to reduce operational burden on FAM DAO members. RCC manages the remuneration and other payout processes. It executes, tracks and reports on payroll and other contributor-related operating expenses.
    						</div>
    					</div>
    				</div>
    			</div>
    			<div className="md:col-span-2" data-aos="zoom-in" data-aos-delay="100">
    				<div className="rounded-5xl md:h-[32rem] bg-gradient-to-br from-[WHY-form] to-[WHY-to] overflow-hidden">
    					<div className="p-8 sm:p-10  dark:text-white text-gray-800">
    						<img className="h-16 w-16 sm:h-18 sm:w-18 text-pwhite text-pgray-800 mb-5" src={rcc}></img>
    						<div className="sm:w-[75%] font-medium text-2xl sm:text-5xl mb-5 leading-tight">
    							RCC
    						</div>
    						<div className="sm:w-[75%] font-medium text-xl sm:text-xl mb-5 leading-tight">
    							The Referral program is designed to encourage software developers of wallets and other DeFi protocols to promote our staking protocol among their users.
    							The Referral program committee controls the full life-cycle of the referral program.
    						</div>
    					</div>	
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
  );
}

