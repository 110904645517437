import React,{useContext,useEffect} from 'react'
import { themeContext } from '../../../store/configProvider.tsx'
import AOS from 'aos';
import 'aos/dist/aos.css';

import ecosystem from '../../../assets/ecosystem-bg.png';
import ecosystem_game from '../../../assets/ecosystem-game.png';
import ecosystem_star from '../../../assets/ecosystem-star.png';

import ecosystem_first_bg from '../../../assets/ecosystem-first-bg.png';
import ecosystem_star_bg from '../../../assets/ecosystem-star-bg.png';

export default ()=> {
	// <a  className="rounded-5xl border-none px-8 py-2 cursor-pointer hover:bg-ECOSYSTEM">
	// 	Coming soon
	// </a>
	useEffect(() => {
		 AOS.init();
	}, [])
	const { theme } = React.useContext(themeContext)
  return (
    <div className="max-w-8xl mx-auto pt-16 sm:pt-32 px-4 sm:px-6 lg:px-6">
		<a name="ECOSYSTEM"></a>
    	<div className="text-5xl sm:text-6xl dark:text-white text-gray-800 mb-10 text-left sm:text-center">
    		ECOSYSTEM
    	</div>
		<div className="text-xl text-gray-900 dark:text-white text-left sm:text-center">
			
		</div>
		<div className="grid grid-cols-1 gap-x-8 gap-y-8 mt-12 h-[48rem] md:h-[32rem]">
			<div className="group">
				<div className="min-h-[20rem] bg-cover bg-center overflow-hidden rounded-5xl h-full" style={{ backgroundImage: `url(${ecosystem})` }}>
					<div className="p-10 sm:p-18 h-full flex flex-col">
						<div className="items-center">
							<div data-aos="fade-right" data-aos-delay="100" className="my-12 text-white text-2xl sm:text-4xl w-full  xl:w-2/3">
								We are about to launch the first Lucky-FAM ecological application, it is a simple number game, you can win up to 600 times the reward.
							</div>
							<a data-aos="fade-right" data-aos-delay="200" className="rounded-5xl border-none text-3xl text-comeSoon font-semibold">
								Coming soon
							</a>
							<div data-aos="fade-right" data-aos-delay="300" className="my-12 text-white text-lg sm:text-xl w-full md:w-1/2">
								Our NFT, GameFi ecosystem is coming
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
  );
}

