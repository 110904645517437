import React,{useState,useContext} from 'react'
import { Link } from 'react-router-dom'
import { themeContext } from '../../store/configProvider.tsx'

import logo from '../../assets/logo.png';
import logo_light from '../../assets/logo_light.png';
import more from '../../assets/more.png';

import discord from '../../assets/discord.png';
import twitter from '../../assets/twitter.png';
import telegram from '../../assets/telegram.png';



export default ()=> {
	const { theme,changeTheme } = React.useContext(themeContext)
	
	const [menu, setMenu] = useState([
		{
			name:'HOME',
			url:'/Home',
			target:'_self',
			child:[]
		},
		{
			name:'FAM FINANCE',
			url:'',
			child:[
				{
					name:'Overview',
					url:'https://finance.famdao.org/#/home',
				},
				{
					name:'Price',
					url:'https://info.famdao.org/',
				},
				{
					name:'Swap',
					url:'https://finance.famdao.org/#/swap',
				},
				{
					name:'Farms',
					url:'https://finance.famdao.org/#/mining',
				},
				{
					name:'My Assets',
					url:'https://finance.famdao.org/#/asset',
				}
			]
		},
		{
			name:'GOVERNANCE',
			url:'https://vote.famdao.org/',
			child:[]
		},
		{
			name:'ECOSYSTEM',
			url:'#ECOSYSTEM',
			target:'_self',
			child:[]
		},
		{
			name:'FAMILY',
			url:'',
			child:[
				{
					name:'Twitter',
					url:'https://twitter.com/FAM_DAO',
				},
				{
					name:'Telegram',
					url:'https://t.me/famdao',
				},
				{
					name:'Discord',
					url:'https://discord.gg/FqEsjM8EmU',
				}
			]
		},
		// {
		// 	name:'LEARN',
		// 	url:'',
		// 	child:[]
		// },
	]);
	
	const [navi, setNavi] = useState(false);
	const toggle_navi = () => {
	    setNavi(!navi)
	}
	
	const change = () => {
		if(!localStorage.theme || localStorage.theme == 'dark'){
			localStorage.theme = 'light';
			document.documentElement.classList.remove('dark')
		}else{
			localStorage.theme = 'dark';
			document.documentElement.classList.add('dark')
		}
	}
	
	// <div className=""></div>
  return (
	<div className="header">
		<div className="fixed t-0 w-full z-30 backdrop-filter backdrop-blur-xl dark:hover:bg-gray-900/10">
			<div className="max-w-7xl mx-auto px-4 sm:px-6">
				<div className="flex items-center py-4 md:space-x-10 justify-between">
					<a className="flex justify-center space-x-4">
						<img className="my-auto h-7 w-auto sm:h-8" src={theme=='dark'?logo:logo_light}/>
					</a>
					<div className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0 space-x-6">
						{
							menu.map((item)=>{
								return (
									<div key={item.name} className="relative group">
										{
											item.url ?(
												<a href={item.url} target={item.target?item.target:'_blank'}>
													<div className="whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-transparent rounded-lg text-base font-medium text-gray-900 dark:text-white group-hover:bg-white/0 group-hover:dark:bg-transparent backdrop-blur-xl bg-opacity-2 bg-white group-hover:backdrop-blur-none hover:!bg-white/30 dark:hover:bg-gray-600 cursor-pointer">
														{item.name}
													</div>
												</a>
											):(
												<button className="whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-transparent rounded-lg text-base font-medium text-gray-900 dark:text-white group-hover:bg-white/0 group-hover:dark:bg-transparent backdrop-blur-xl bg-opacity-2 bg-white group-hover:backdrop-blur-none hover:!bg-white/30 dark:hover:bg-gray-600 cursor-pointer">
													<span className="dark:text-white text-gray-900">{item.name}</span>
												</button>
											)
										}
										{
											item.child.length ? (
												<div className="absolute right-0 hidden group-hover:flex shadow-3xl">
													<div className="mt-2">
														<div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-3 flex flex-col shadow-lg highlight w-48">
															<div className="w-full">
																<ul className="w-full">
																	{
																		item.child.map((child)=>{
																			return (
																				<a key={child.name} href={child.url?child.url:''} target={item.target?item.target:'_blank'}>
																					<li className="text-gray-900 dark:text-white font-medium py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full rounded cursor-pointer">
																						{child.name}
																					</li>
																				</a>
																			)
																		})
																	}
																</ul>
															</div>
														</div>
													</div>
												</div>
											):('')
										}
									</div>
								)
							})
						}
					</div>
					<div className="cursor-pointer flex lg:hidden select-none content-end">
						<img src={more} className="block w-8 h-8" onClick={setNavi}/>
					</div>
				</div>
			</div>
		</div>
		<div className="fixed z-50 w-3/5 sm:w-2/4 h-screen backdrop-filter backdrop-blur-xl transform-gpu duration-700 select-none" style={navi ? { transform: 'translateX(0)' } : { transform: 'translateX(-80vw)' }}>
			<div className="px-8 my-4">
				<img src={logo} className="h-7 w-auto"/>
			</div>
			<div className="my-6 text-base text-left px-8 overflow-y-auto">
				{
					menu.map((item,index)=>{
						return (
							<div key={item.name}  className="mb-2">
								{
									item.url ?(
										<a href={item.url} target={item.target?item.target:'_blank'}>
											{item.name}
										</a>
									):(
										<div>
											{item.name}
										</div>
									)
								}
								{
									item.child.length ? (
										<div className="text-base pl-4 py-2">
											{
												item.child.map((child)=>{
													return (
														<a key={child.name} href={child.url?child.url:''} target={item.target?item.target:'_blank'} className="flex p-2">
															<span>
																{child.name}
															</span>
														</a>
													)
												})
											}
										</div>
									):('')
								}
							</div>
						)
					})
				}
			</div>
		</div>
		<div className="fixed z-50 h-screen right-0" style={navi ? { width: '40%' } : { width: '0' }} onClick={toggle_navi}></div>
	</div>
  );
}

