import React,{useContext,useEffect} from 'react'
import { themeContext } from '../../../store/configProvider.tsx'

import AOS from 'aos';
import 'aos/dist/aos.css';

import tokenomics from '../../../assets/tokenomics.png';
import tokenomics_light from '../../../assets/tokenomics_light.png';

export default ()=> {
	const { theme } = React.useContext(themeContext);
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="max-w-8xl mx-auto pt-16 sm:pt-32 px-4 sm:px-6 lg:px-6">
    	<div className="text-5xl sm:text-6xl dark:text-white text-gray-800 mb-10 text-left sm:text-center">
    		FAMDAO Tokenomics
    	</div>
		<div className="text-xl text-gray-900 dark:text-white text-left sm:text-center">
			The companies,organizations, and foundations that support the ongoing development of the FAM Protocol
		</div>
		<div className="grid  grid-cols-1 sm:grid-cols-2 mt-16 mx-auto w-full lg:w-3/4 xl:w-2/4">
			<div className="col-span-1 pl-8 text-left">
				<ul data-aos="fade-right">
					<li className="mt-2 flex items-center">
						<span className="w-4 h-4 bg-token1 inline-block mr-4"></span>
						<span className="text-gray-900 dark:text-white">Community Construction 10%</span>
					</li>
					<li className="mt-2 flex items-center">
						<span className="w-4 h-4 bg-token2 inline-block mr-4"></span>
						<span className="text-gray-900 dark:text-white">Ecological Construction 10%</span>
					</li>
				</ul>
			</div>
			<div className="col-span-1 pl-8 text-left">
				<ul data-aos="fade-left">
					<li className="mt-2 flex items-center">
						<span className="w-4 h-4 bg-token3 inline-block mr-4"></span>
						<span className="text-gray-900 dark:text-white">Metaverse Ecological Reserve 20%</span>
					</li>
					<li className="mt-2 flex items-center">
						<span className="w-4 h-4 bg-token4 inline-block mr-4"></span>
						<span className="text-gray-900 dark:text-white">DAO Organization Driver 20%</span>
					</li>
					<li className="mt-2 flex items-center">
						<span className="w-4 h-4 bg-token5 inline-block mr-4"></span>
						<span className="text-gray-900 dark:text-white">Mining 40%</span>
					</li>
				</ul>
			</div>
		</div>
		<div className="w-full" data-aos="zoom-in" data-aos-delay="200">
			<img src={theme=='dark'?tokenomics:tokenomics_light}/>
		</div>
    </div>
  );
}

